import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#4a4a4a",
      color: "#14141c",
      fontWeight: 400,
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#4a4a4a",
      },
      "::placeholder": {
        color: "#4a4a4a",
      },
    },
    invalid: {
      iconColor: "#4a4a4a",
      color: "#4a4a4a",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow stripe">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput input"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const CheckoutForm = () => {
  const history = useHistory();
  let { password, id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: "",
  });
  const isStage1 = localStorage.getItem("stage-1");
  const isStage2 = localStorage.getItem("stage-2");

  function checkPassword(history, password) {
    axios
      .get(process.env.REACT_APP_API_URL + `/checkout/${password}`)
      .then((response) => {
        if (response.data.error) {
          history.push("/");
        }
        setAmount(response.data.amount);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (JSON.parse(isStage1) === false || JSON.parse(isStage2) === false) {
    return <Redirect to="/" />;
  } else {
    checkPassword(history, password);
  }

  function chargeCard(payment_id, amount, id) {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "/chargeCard",
      data: { payment_id: payment_id, amount: amount },
    })
      .then((res) => {
        if (res.data.error == false) {
          history.push("/");
          localStorage.setItem("stage-1", false);
          localStorage.setItem("stage-2", false);
          setProcessing(true);
        }
      })
      .catch((error) => {
        console.log(error);
        history.goBack();
      });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });
    if (!error) {
      chargeCard(paymentMethod.id, amount, id);
    }
  };

  return (
    <div className="columns mt-6 is-centered">
      <div class="column is-two-fifths">
        <div class="card p-5">
          <h2 class="title has-text-centered">Authorize Card</h2>
          <form className="Form" onSubmit={handleSubmit}>
            <fieldset className="FormGroup">
              <Field
                id="name"
                className="mb-2"
                type="text"
                placeholder="Your Name"
                required
                autoComplete="name"
                value={billingDetails.name}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value,
                  });
                }}
              />
              <Field
                id="email"
                type="email"
                placeholder="Your Email"
                required
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset className="FormGroup card">
              <CardField />
            </fieldset>
            <button
              className="SubmitButton button is-success is-fullwidth"
              disabled={!stripe || processing}
              type="submit"
            >
              Authorize &pound;{amount}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default function StripeCheckout() {
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY);

  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}
